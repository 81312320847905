import { type MutableRefObject, useCallback } from 'react';
import type { EditableCallbackParams, GridApi, NewValueParams } from '@ag-grid-community/core';

import type { DataGridProps } from '../../DataGrid';
import type { GridOptions, Row } from '../../types';
import { useEffectAfterFirstRender, useGridLogger, useInitialiseState } from '../utils';

export const useEditingFeature = <R extends Row>(
  gridRef: MutableRefObject<GridOptions<R>>,
  gridApiRef: MutableRefObject<GridApi<R> | undefined>,
  props: Pick<DataGridProps<R>, 'columns' | 'isCellEditable' | 'onCellValueChange'>
) => {
  const logger = useGridLogger(gridRef, 'useEditingFeature');

  const { columns, isCellEditable: isCellEditableProp, onCellValueChange } = props;

  const handleCellValueChange = useCallback(
    (e: NewValueParams) => {
      onCellValueChange?.(e);
    },
    [onCellValueChange]
  );

  const isCellEditable = useCallback(
    (params: EditableCallbackParams) => {
      const column = columns.find(col => col.field === params.colDef.field);
      if (column?.editable !== true) {
        return false;
      }
      if (isCellEditableProp) {
        return isCellEditableProp(params);
      }
      return true;
    },
    [columns, isCellEditableProp]
  );

  useInitialiseState(() => {
    gridRef.current.defaultColDef.editable = isCellEditable;
    gridRef.current.defaultColDef.onCellValueChanged = handleCellValueChange;
  });

  useEffectAfterFirstRender(() => {
    logger.log('Updating isCellEditable');

    gridApiRef.current?.setGridOption('defaultColDef', {
      ...gridRef.current.defaultColDef,
      editable: isCellEditable,
    });
  }, [isCellEditable]);

  useEffectAfterFirstRender(() => {
    throw new TypeError('Controlling `onCellValueChange` is not supported.');
  }, [handleCellValueChange]);
};
