import { type EntitiesLookup } from 'src/sync/types';
import type { Entity } from 'src/types/Entity';
import { type OrganicWineSalesEntity } from 'src/types/entity/OrganicWineSales';
import { type DenormalizedOrganicWineSales } from '../types/DenormalizedOrganicWineSales';
import type { DenormalizedWineVarietalSaleData } from '../types/DenormalizedWineVarietalSaleData';

export const denormalizeBrandLine = (entitiesLookup: EntitiesLookup, brandLine: Entity): Entity => {
  const brand = entitiesLookup['brands']?.get(brandLine['brandGUID'] as string);
  const origin = entitiesLookup['origins']?.get(brandLine['originId'] as string);
  const owner = entitiesLookup['owners']?.get(brand?.['ownerGUID'] as string);
  const ageStyle = entitiesLookup['ageStyles']?.get(brandLine['ageId'] as string);
  const alcoholicStrength = entitiesLookup['alcoholicStrengths']?.get(
    brandLine['alcoholicStrengthId'] as string
  );

  const maltRegion = entitiesLookup['maltRegions']?.get(brandLine['maltRegionId'] as string);
  const category5 = entitiesLookup['categories']?.get(`5-${brandLine['category5Id'] as string}`);
  const category4 = entitiesLookup['categories']?.get(`4-${category5?.['parentId'] as string}`);
  const category3 = entitiesLookup['categories']?.get(`3-${category4?.['parentId'] as string}`);
  const category2 = entitiesLookup['categories']?.get(`2-${category3?.['parentId'] as string}`);
  const category1 = entitiesLookup['categories']?.get(`1-${category2?.['parentId'] as string}`);

  return {
    ...brand,
    ...origin,
    ...owner,
    ...ageStyle,
    ...alcoholicStrength,
    bleName: brandLine['isBLE'] ? 'Brand Line Extension' : 'Non-Brand Line Extension',
    ...maltRegion,
    category5Id: category5?.['id'],
    category5Name: category5?.['name'],
    category4Id: category4?.['id'],
    category4Name: category4?.['name'],
    category3Id: category3?.['id'],
    category3Name: category3?.['name'],
    category2Id: category2?.['id'],
    category2Name: category2?.['name'],
    category1Id: category1?.['id'],
    category1Name: category1?.['name'],
    approvalStatus: 0,
    ...brandLine,
  };
};
export const denormalizeWineVarietalSaleDataUtil = (
  entitiesLookup: Record<string, Map<string | number, Entity>>,
  wineVarietalSaleData: Entity
): DenormalizedWineVarietalSaleData => {
  const wineVarietalOption = entitiesLookup['wineVarietalOptions']?.get(
    wineVarietalSaleData['wineVarietalOptionId'] as string
  );
  const origin = entitiesLookup['origins']?.get(wineVarietalSaleData['originId'] as string);

  return {
    ...wineVarietalSaleData,
    ...wineVarietalOption,
    ...origin,
  } as DenormalizedWineVarietalSaleData;
};

export const denormalizeOrganicWineDataUtil = (
  organicWineSales: OrganicWineSalesEntity
): DenormalizedOrganicWineSales => ({
  ...organicWineSales,
  organic: organicWineSales.isOrganic ? 'Organic' : 'Non-Organic',
});

export const denormalizeEcommerceDataUtil = (
  entitiesLookup: Record<string, Map<string, Entity>>,
  ecommerceSales: Entity
) => {
  const category2 = entitiesLookup['categories']?.get(
    `2-${ecommerceSales['category2Id'] as string}`
  );
  const category1 = entitiesLookup['categories']?.get(`1-${category2?.['parentId'] as string}`);

  return {
    ...ecommerceSales,
    category2Id: category2?.['id'],
    category2Name: category2?.['name'],
    category1Id: category1?.['id'],
    category1Name: category1?.['name'],
  };
};

const getBrandLine = (entitiesLookup: EntitiesLookup, brandSale: Entity) => {
  const brandLine = entitiesLookup['brandLines']?.get(brandSale['brandLineGUID'] as string);

  return brandLine ? denormalizeBrandLine(entitiesLookup, brandLine) : brandLine;
};

export const getDenormalizedSaleData = (brandSale: Entity, entitiesLookup: EntitiesLookup) => {
  const brandLine = getBrandLine(entitiesLookup, brandSale);
  const priceBand = entitiesLookup['priceBands']?.get(brandSale['priceBandId'] as string);
  const distributor = entitiesLookup['distributors']?.get(brandSale['distributorGUID'] as string);
  const category5 = entitiesLookup['categories']?.get(`5-${brandLine?.['category5Id'] as string}`);
  const forecastByOrigin = category5?.['forecastByOrigin'];

  return {
    ...distributor,
    ...brandLine,
    ...priceBand,
    forecastByOrigin,
    ...brandSale,
  };
};

export const getDenormalizedExternalData = (
  externalData: Entity,
  entitiesLookup: EntitiesLookup
) => {
  const brandSale = entitiesLookup['brandSales']?.get(externalData['saleGUID'] as string);
  const brandSaleData = { ...brandSale, ...externalData };

  return getDenormalizedSaleData(brandSaleData, entitiesLookup);
};

export const denormalizeOnPremisesSalesDataUtil = (
  entitiesLookup: EntitiesLookup,
  onPremiseSales: Entity
): Entity => {
  const origin = entitiesLookup['origins']?.get(onPremiseSales['originId'] as string);
  const priceBand = entitiesLookup['priceBands']?.get(onPremiseSales['priceBandId'] as string);
  const category5 = entitiesLookup['categories']?.get(
    `5-${onPremiseSales['category5Id'] as string}`
  );
  const category4 = entitiesLookup['categories']?.get(`4-${category5?.['parentId'] as string}`);
  const category3 = entitiesLookup['categories']?.get(`3-${category4?.['parentId'] as string}`);
  const category2 = entitiesLookup['categories']?.get(`2-${category3?.['parentId'] as string}`);
  const category1 = entitiesLookup['categories']?.get(`1-${category2?.['parentId'] as string}`);
  const forecastByOrigin = category5?.['forecastByOrigin'];

  return {
    ...origin,
    ...priceBand,
    category5Id: category5?.['id'],
    category5Name: category5?.['name'],
    category4Id: category4?.['id'],
    category4Name: category4?.['name'],
    category3Id: category3?.['id'],
    category3Name: category3?.['name'],
    category2Id: category2?.['id'],
    category2Name: category2?.['name'],
    category1Id: category1?.['id'],
    category1Name: category1?.['name'],
    forecastByOrigin,
    ...onPremiseSales,
  };
};
