/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { type ColDefField, type ColumnMenuTab } from '@ag-grid-community/core';
import { set as setProperty } from 'dot-prop';

import { isFeatureFlagSet } from 'src/feature-flags';
import type { unsafe_ValueFormatterParams } from '.';
import { CellTooltip } from './components/CellTooltip';
import CustomSetFilter from './CustomSetFilter';
import {
  type BaseColumn,
  type ColDef,
  type ColGroupDef,
  type Column,
  type ColumnGroup,
  type EntityColumn,
  type EstimatedValue,
  type FilterModel,
  FilterOperator,
  type FormulaColumn,
  type LinkedRecordColumn,
  type NumberCellValue,
  type NumberColumn,
  type Row,
  type SelectColumn,
  type TextColumn,
} from './types';

const sortCollator = new Intl.Collator('en');

function createBaseColumnDef<R extends Row>(col: BaseColumn): ColDef<R> {
  const checkboxSelection = isFeatureFlagSet('checkbox-selection');
  return {
    colId: col.field,
    enableRowGroup: col.primary ? false : col.groupable ?? false,
    field: col.field as ColDefField<R>,
    filter: col.filterable === false ? false : undefined,
    headerName: col.headerName,
    headerTooltip: col.description ?? col.headerName,
    lockPinned: col.primary ? true : col.pinnable === false ? true : false,
    checkboxSelection: checkboxSelection && !!col.primary,
    headerCheckboxSelection: checkboxSelection && !!col.primary,
    initialWidth: col.initialWidth ?? 100,
    maxWidth: col.maxWidth ?? Infinity,
    minWidth: col.minWidth ?? 50,
    resizable: col.resizable ?? true,
    sortable: col.sortable ?? true,
    type: col.type,
    initialHide: !(col.visible ?? true),
    suppressFillHandle: !col.enableFillHandle,
    ...(col.tooltip && {
      tooltipComponent: CellTooltip,
      tooltipField: col.field as ColDefField<R>,
      tooltipComponentParams: { tooltipOptions: col.tooltipOptions },
    }),
  };
}

function createFormulaColumnDef<R extends Row>(col: FormulaColumn): ColDef<R> {
  const { currency, format = 'decimal', formula, precision = 2 } = col.options;

  return {
    ...createBaseColumnDef<R>(col),
    valueFormatter: ({ value }) => {
      if (!value || !isNumber(value)) {
        return value as unknown;
      }
      return value.toLocaleString(undefined, {
        currency: format === 'currency' ? currency : undefined,
        style: format,
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      });
    },
    valueGetter: ({ context, node }) => {
      const data = node?.group ? (node.aggData as Partial<R>) : node?.data;
      const rowNode = { grouped: Boolean(node?.group) };
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      const formulaFn = new Function(
        'ctx, data, rowNode',
        `
          try {
            return ctx.formulaFunctions.${formula}
          } catch (e) {
            if (
              e.message.includes('Cannot read properties of undefined') ||
              e.message.includes('Cannot read properties of null') ||
              e.message.includes('is undefined') ||
              e.message.includes('is null')
            ) {
              return undefined;
            }
            throw e;
          }
        `
      ) as (context: unknown, data: unknown, rowNode: unknown) => number | null | undefined;
      return formulaFn(context, data, rowNode);
    },
    filterValueGetter: params => {
      const { getValue } = params;
      const { field }: { field: string } = col;
      const colValue = getValue(field) as number | undefined | null;

      if (typeof colValue === 'undefined' || colValue === null) return colValue;

      return format === 'percent' ? Number((colValue * 100).toFixed(precision)) : Number(colValue);
    },
    filterParams: {
      defaultOption: FilterOperator.GreaterThan,
    },
  } as ColDef<R>;
}

function createLinkedRecordColumnDef<R extends Row>(col: LinkedRecordColumn): ColDef<R> {
  /* LinkedRecord column is very similar to Text column */

  return {
    ...createBaseColumnDef<R>(col),
    // Using || on purpose to convert empty strings to null
    valueParser: ({ newValue }) => trimAndCollapseSpaces(newValue) || null,
    comparator: (a: string, b: string) => sortCollator.compare(a, b),
  } as ColDef<R>;
}

function createNumberColumnDef<R extends Row>(col: NumberColumn): ColDef<R> {
  const { currency, format = 'decimal', precision = 2, min, max } = col.options ?? {};

  const getCellValue = (cellValue: NumberCellValue): number | null => {
    if (typeof cellValue === 'undefined' || cellValue === null) return null;
    return typeof cellValue !== 'object' ? Number(cellValue) : Number(cellValue.value);
  };

  const getFilterValue = (filterValues: number[]): number | null =>
    filterValues.length === 0 || typeof filterValues[0] === 'undefined'
      ? null
      : Number(filterValues[0]);

  const getDecimalPlaces = (value: number): number => {
    const str = value.toString();
    const decimalIndex = str.indexOf('.');
    return decimalIndex >= 0 ? str.length - decimalIndex - 1 : 0;
  };

  return {
    ...createBaseColumnDef<R>(col),
    valueFormatter: ({ value, type: target }: unsafe_ValueFormatterParams & { type?: string }) => {
      if (!value && value !== 0 && format !== 'percent') return value as undefined;
      if (value && isString(value) && value.toString().startsWith('.')) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, no-param-reassign
        value = `0${value}`;
      }
      const valueToFormat = (
        col.estimatable === true ? (value as EstimatedValue).value : value
      ) as unknown;

      if (!isNumber(valueToFormat)) return valueToFormat;

      const valueFormatted = valueToFormat.toLocaleString(undefined, {
        currency: format === 'currency' ? currency : undefined,
        style: format,
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      });

      if (col.estimatable === true && (value as EstimatedValue).isEstimated && target !== 'excel') {
        return `~${valueFormatted}`;
      }

      if (target !== 'excel' || typeof valueFormatted === 'undefined') {
        return valueFormatted;
      }

      const decimalPlaces = valueFormatted.includes('.') ? valueFormatted.split('.')[1]!.length : 0;

      return parseFloat(valueFormatted.replace(/,/g, '')).toFixed(decimalPlaces);
    },
    cellEditorParams: {
      useFormatter: true,
    },
    filterValueGetter: params => {
      const { getValue } = params;
      const { field }: { field: string } = col;
      const valueField = getValue(field) as NumberCellValue;

      if (typeof valueField === 'undefined' || valueField === null) return valueField;

      const colValue = typeof valueField !== 'object' ? valueField : valueField.value;

      return format === 'percent' ? Number(colValue * 100).toFixed(precision) : Number(colValue);
    },
    valueParser: ({ newValue }) => {
      const getNumberValue = (value: string) => {
        let parsedValue;
        if (value.match(/=*\d{1,}[*+-,.\\=^e]*\d+$/)) {
          let tempValue = value.toLowerCase();
          tempValue = tempValue.startsWith('=') ? tempValue.slice(1) : tempValue;
          tempValue = tempValue.replaceAll('^', 'e');

          if (hasClientUKRegionalSettings()) {
            // remove decimal format grouping (,)
            tempValue = tempValue.replaceAll(',', '');
          } else {
            // remove decimal format grouping (.)
            // replace decimal place (,) with (.)
            tempValue = tempValue.replaceAll('.', '');
            tempValue = tempValue.replaceAll(',', '.');
          }
          parsedValue = Number((0, eval)(tempValue));
        }

        if (value.match(/^\d+$/)) {
          parsedValue = Number(value);
        }

        if (value && isString(value) && value.toString().startsWith('.')) {
          parsedValue = Number(`0${value}`);
        }

        if (col.options?.format === 'percent' && value.match('\\d+(\\.\\d+)?%')) {
          parsedValue = Number(value.replace('%', ''));
        }

        if (col.options?.format === 'percent' && isNumber(parsedValue)) {
          // this control is required because if researcher types any float number with having more than 2 decimal places,
          // this value should be rejected which is happening in setvalue function
          // in order to allow setvalue to reject this value parsedValue has been set as string
          // when number of decimal places is bigger than precision
          if (getDecimalPlaces(parsedValue) > precision) {
            parsedValue = '';
          } else {
            // dividing by 100 might return long decimal numbers like when you try to type 37.02/100 it returns 0.37020000000000003
            // therefore number of decimal places should be specified otherwise, valueSetter would not
            // accept this value because of having more decimal places than expected.
            parsedValue = Number((parsedValue / 100).toPrecision(precision + 2));
          }
        }

        return parsedValue ?? value;
      };

      try {
        if (newValue === '') return null;

        const useValue = isString(newValue) ? newValue.trim() : (newValue as number);

        if (col.estimatable === true && isString(useValue)) {
          return {
            isEstimated:
              useValue.startsWith('~') ||
              useValue.startsWith('--') ||
              useValue.startsWith('=--') ||
              useValue.startsWith('=~'),
            value: getNumberValue(useValue.replace('~', '').replace('--', '')),
          };
        } else {
          return getNumberValue(String(useValue));
        }
      } catch {
        return String(newValue);
      }
    },
    valueSetter: ({ column, data, newValue }) => {
      if (newValue != null) {
        const valueToCheck = (
          col.estimatable === true ? (newValue as EstimatedValue).value : newValue
        ) as unknown;

        if (!isNumeric(valueToCheck)) return false;
        if (isNumeric(min) && Number(valueToCheck) < min) return false;
        if (isNumeric(max) && Number(valueToCheck) > max) return false;
        if (typeof valueToCheck !== 'number') return false;

        const decimalPlaces = getDecimalPlaces(valueToCheck);

        if (decimalPlaces) {
          if (format === 'decimal' && decimalPlaces > precision) return false;
          if (format === 'percent' && decimalPlaces > precision + 2) return false;
        }
      }

      setProperty(data, column.getColId(), newValue);
      return true;
    },
    filterParams: {
      defaultOption: FilterOperator.GreaterThan,
      filterOptions: [
        {
          displayKey: FilterOperator.Equals,
          displayName: 'Equals',
          predicate: (filterValues: number[], cellValue: NumberCellValue) => {
            const cval = getCellValue(cellValue);
            const fval = getFilterValue(filterValues);
            return cval !== null && fval !== null ? cval === fval : false;
          },
        },
        {
          displayKey: FilterOperator.NotEqual,
          displayName: 'Not equal',
          predicate: (filterValues: number[], cellValue: NumberCellValue) => {
            const cval = getCellValue(cellValue);
            const fval = getFilterValue(filterValues);
            return cval !== null && fval !== null ? cval !== fval : false;
          },
        },
        {
          displayKey: FilterOperator.LessThan,
          displayName: 'Less than',
          predicate: (filterValues: number[], cellValue: NumberCellValue) => {
            const cval = getCellValue(cellValue);
            const fval = getFilterValue(filterValues);
            return cval !== null && fval !== null ? cval < fval : false;
          },
        },
        {
          displayKey: FilterOperator.LessThanOrEqual,
          displayName: 'Less than or equals ',
          predicate: (filterValues: number[], cellValue: NumberCellValue) => {
            const cval = getCellValue(cellValue);
            const fval = getFilterValue(filterValues);
            return cval !== null && fval !== null ? cval <= fval : false;
          },
        },
        {
          displayKey: FilterOperator.GreaterThan,
          displayName: 'Greater than',
          predicate: (filterValues: number[], cellValue: NumberCellValue) => {
            const cval = getCellValue(cellValue);
            const fval = getFilterValue(filterValues);
            return cval !== null && fval !== null ? cval > fval : false;
          },
        },
        {
          displayKey: FilterOperator.GreaterThanOrEqual,
          displayName: 'Greater than or equals',
          predicate: (filterValues: number[], cellValue: NumberCellValue) => {
            const cval = getCellValue(cellValue);
            const fval = getFilterValue(filterValues);
            return cval !== null && fval !== null ? cval >= fval : false;
          },
        },
        'blank',
        'notBlank',
      ],
    },
    comparator: (
      valueA: { isEstimated: boolean; value: number } | number | undefined | null,
      valueB: { isEstimated: boolean; value: number } | number | undefined | null
    ) => {
      if (valueA == null && valueB == null) {
        return 0;
      } else if (valueA == null) {
        return -1;
      } else if (valueB == null) {
        return 1;
      }

      return (
        (typeof valueA === 'object' ? valueA.value : valueA) -
        (typeof valueB === 'object' ? valueB.value : valueB)
      );
    },
  } as ColDef<R>;
}

function createSelectColumnDef<R extends Row>(col: SelectColumn): ColDef<R> {
  const { choices, editor } = col.options;

  return {
    ...createBaseColumnDef<R>(col),
    valueFormatter: params => {
      const {
        value,
        colDef: { refData },
      } = params;

      return refData?.[value] ? refData[value] : '';
    },

    cellEditorParams: {
      values: choices.filter(choice => !choice.hideInEditor).map(choice => choice.value),
    },
    cellEditorPopup: false,
    refData: choices.reduce(
      (acc, choice) => ({
        ...acc,
        [typeof choice.value === 'boolean' ? String(choice.value) : choice.value]: choice.label,
      }),
      {}
    ),
    comparator: (a: string, b: string) => {
      const choiceA = choices.find(choice => String(choice.value) === String(a))?.label ?? a;
      const choiceB = choices.find(choice => String(choice.value) === String(b))?.label ?? b;

      return sortCollator.compare(choiceA, choiceB);
    },
    ...(editor === 'dialog' ? { editable: false } : {}),
  } as ColDef<R>;
}

const sortByDisplayOrder = (
  { displayOrder }: { displayOrder?: number },
  { displayOrder: otherDisplayOrder }: { displayOrder?: number }
) => (displayOrder ?? 0) - (otherDisplayOrder ?? 0);

function createEntityColumnDef<R extends Row>(col: EntityColumn): ColDef<R> {
  const { choices, editor, orderField } = col.options;
  return {
    ...createBaseColumnDef<R>(col),
    valueFormatter: params => {
      const {
        value,
        colDef: { refData },
      } = params;

      return refData?.[value] ? refData[value] : '';
    },
    cellEditorParams: {
      values: choices.every(c => typeof c.displayOrder !== 'undefined')
        ? choices
            .slice()
            .sort(sortByDisplayOrder)
            .map(choice => choice.value)
        : choices.map(choice => choice.value),
    },
    refData: choices.reduce(
      (acc, choice) => ({
        ...acc,
        [typeof choice.value === 'boolean' ? String(choice.value) : choice.value]: choice.label,
      }),
      {}
    ),
    comparator: (a, b, nodeA, nodeB, isDescending) => {
      const choiceA = choices.find(choice => String(choice.value) === String(a));
      const choiceB = choices.find(choice => String(choice.value) === String(b));
      if (orderField && choiceA != null && choiceB != null) {
        const sortValue = sortByDisplayOrder(
          { displayOrder: Number(choiceA.displayOrder) },
          { displayOrder: Number(choiceB.displayOrder) }
        );

        // Even though nodeA and nodeB should always be defined, in practice sometimes they are not.
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (nodeA?.group && nodeB?.group) {
          return isDescending ? -sortValue : sortValue;
        }

        return sortValue;
      }

      if (typeof orderField === 'undefined' && choiceA != null && choiceB != null) {
        if (
          typeof choiceA.displayOrder === 'undefined' &&
          typeof choiceB.displayOrder === 'undefined'
        ) {
          return sortCollator.compare(choiceA.label, choiceB.label);
        }
      }

      return sortCollator.compare(String(a), String(b));
    },
    ...(editor === 'dialog' ? { editable: false } : {}),
  } as ColDef<R>;
}

// Remove leading and trailing spaces
// Collapse multiple adjucent spaces to a single one
const trimAndCollapseSpaces = (str: unknown) =>
  str === undefined || str === null ? '' : String(str).split(' ').filter(Boolean).join(' ').trim();

function createTextColumnDef<R extends Row>(col: TextColumn): ColDef<R> {
  const { allowedCharacters, largeText, maxLength } = col.options ?? {};
  return {
    ...createBaseColumnDef<R>(col),
    // Using || on purpose to convert empty strings to null
    valueParser: ({ newValue }) => trimAndCollapseSpaces(newValue) || null,
    valueSetter: ({ column, data, newValue }) => {
      if (
        newValue &&
        allowedCharacters &&
        String(newValue)
          .split('')
          .some(ch => !allowedCharacters.includes(ch))
      ) {
        // When specified, allow only allowed characters in the value
        return false;
      }

      // when maxLength defined, check if the new values character length is less than maxLength
      if (newValue && maxLength && newValue.length > maxLength) {
        return false;
      }

      setProperty(data, column.getColId(), newValue);
      return true;
    },
    filterParams: {
      defaultOption: 'contains',
    },
    comparator: (a: string, b: string) => sortCollator.compare(a, b),
    ...(largeText && { cellEditor: 'agLargeTextCellEditor', cellEditorPopup: true }),
    ...(maxLength !== undefined && { cellEditorParams: { maxLength } }),
  } as ColDef<R>;
}

export function createColumnDef<R extends Row>(
  col: Column,
  {
    canChangeColumnVisibility,
    canChangeColumnOrder,
    filter,
    filterIsViewDefined,
  }: {
    canChangeColumnVisibility?: boolean | undefined;
    canChangeColumnOrder?: boolean | undefined;
    filter?: FilterModel | undefined;
    filterIsViewDefined?: boolean | undefined;
  } = {}
): ColDef<R> {
  let colDef: ColDef<R>;

  switch (col.type) {
    case 'formula':
      colDef = createFormulaColumnDef<R>(col);
      break;
    case 'linkedRecord':
      colDef = createLinkedRecordColumnDef<R>(col);
      break;
    case 'number':
      colDef = createNumberColumnDef<R>(col);
      break;
    case 'select':
      colDef = createSelectColumnDef<R>(col);
      break;
    case 'entity':
      colDef = createEntityColumnDef<R>(col);
      break;
    case 'text':
      colDef = createTextColumnDef<R>(col);
      break;
    default:
      throw new TypeError('Accepted column types are: formula, number, select, text and entity');
  }

  if (filter && filterIsViewDefined) {
    filter.forEach(f => {
      if (colDef.field === f.field && f.operator === FilterOperator.IsAnyOf) {
        colDef.filter = CustomSetFilter;
        colDef.filterParams = { values: f.value };
      }
    });
  }

  const menuTabs = ['generalMenuTab', 'filterMenuTab'];
  if (canChangeColumnVisibility) menuTabs.push('columnsMenuTab');

  colDef.menuTabs = menuTabs as ColumnMenuTab[];

  if (canChangeColumnOrder === false || col.primary || col.moveable === false) {
    colDef.suppressMovable = true;
  }

  if (col.columnGroupShow) {
    colDef.columnGroupShow = col.columnGroupShow;
  }

  return colDef;
}

export function createColumnGroupDef<R extends Row>(group: ColumnGroup): ColGroupDef<R> {
  return {
    children: [],
    groupId: group.groupId,
    headerName: group.headerName,
    headerTooltip: group.description ?? group.headerName,
    marryChildren: true,
  };
}

export function isColGroupDef<R extends Row>(
  colDef: ColDef<R> | ColGroupDef<R>
): colDef is ColGroupDef<R> {
  return 'children' in colDef;
}

export function isNumber(value: unknown): value is number {
  return typeof value === 'number';
}

export function isNumeric(value: unknown): value is number | string {
  return value !== '' && !isNaN(parseFloat(value as string)) && isFinite(value as number);
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

function hasClientUKRegionalSettings() {
  return (1234.56).toLocaleString() === '1,234.56';
}
